import { Component } from "@angular/core";

@Component({
    selector: "bb-shopify-init",
    templateUrl: "shopify-init-dialog.component.html",
})
export class ShopifyInitDialogComponent {
    public readonly affiliateLink = "https://shopify.pxf.io/e4Gdkj";
    public readonly appLink = "https://apps.shopify.com/billbee-de?locale=de";

    public openLink(link: string): void {
        document.location.href = link;
    }
}
